import PositionLevelChoose from '@/component/positionLevelChoose/index.vue'
import EmployeeLevelChoose from '@/component/employeeLevelChoose/index.vue'
import consumer_web_policyConfig_getFlightPolicyDetail from '@/lib/data-service/haolv-default/consumer_web_policyConfig_getFlightPolicyDetail'
import consumer_web_policyConfig_updatePolicyByBusinessType from '@/lib/data-service/haolv-default/consumer_web_policyConfig_updatePolicyByBusinessType'
import consumer_trains_common_queryBusinessOverweightBecause from '@/lib/data-service/haolv-default/consumer_trains_common_queryBusinessOverweightBecause'
import consumer_web_policyConfig_addTravelPolicy from '@/lib/data-service/haolv-default/consumer_web_policyConfig_addTravelPolicy'
import consumer_web_policyConfig_deletePolicyByBusinessType from '@/lib/data-service/haolv-default/consumer_web_policyConfig_deletePolicyByBusinessType'
import consumer_web_policyConfig_queryDiscountType from '@/lib/data-service/haolv-default/consumer_web_policyConfig_queryDiscountType'
import consumer_tPdProductSet from '@/lib/data-service/haolv-default/consumer_tPdProductSet'
export default {
    data() {
        var checkDiscount  = (rule, value, callback) => {
            if (/^[0-9]{1}(\.[0-9])?$/.test(value)) {
                callback()
            } else {
                callback(new Error('只能输入大于0小于9，仅支持一位小数的正数，比如4.9'))
            }
        };
        return {
            loading: false,
            id: '',
            type: '', // add 新增 edit 编辑
            positionVoArr: [],
            employeeVoArr: [],
            formData: {
                businessType: 1,
                beforeAfterTime: '',
                flightControlRules: 1, // 机票提前预定管控规则:1.允许预定,只做提示;2.选择原因,3.不允许预定
                flightDiscountControlRules: 1, // 机票舱等折扣管控规则:1.允许预定,只做提示;2.选择原因,3.不允许预定
                flightLowPriceControlRules: 1, // 机票最低价管控规则:1.允许预定,只做提示;2.选择原因,3.不允许预定
                flightPolicy: 1, // 飞机政策限制:1.不限,2.头等/公务舱及以下,3.经济舱,4.不允许预定
                economyDiscountPrice: 2, // 经济舱位折扣最低价设置 1.开启 2.关闭
                firstAndBusinessDiscountPrice: 2, // 头等/商务舱位折扣最低价设置 1.开启 2.关闭
                superEconomyDiscountPrice: 2, // 超级经济舱位折扣最低价设置 1.开启 2.关闭
                positionIds: [], // 职位id
                userIds: [], // 用户id
                reserveDays: '', // 提前预定:天数
                reserveDaysRadio: 0,
                beforeAfterTimeRadio: 0,
                theDiscount: 0, // 折扣 - 不用了
                economyDiscount: 0, // 经济舱位折扣
                economyOtherDiscount: '', // 经济舱其他折扣value
                firstAndBusinessDiscount: 0, // 头等/商务舱位折扣
                firstAndBusinessOtherDiscount: '', // 头等/商务其他舱位折扣value
                superEconomyDiscount: 0, // 超级经济舱位折扣
                superEconomyOtherDiscount: '', // 超级经济舱其他折扣value
                flightChangeControlRules: 0,
                flightAdditionalService: 0, // 是否允许机票增值服务 0不允许 1允许
                flightAdditionalServiceMoney: '', // 增值服务上线金额
            },
            rule: {
                firstAndBusinessOtherDiscount: [
                    { required: true, message: '请输入折扣', trigger: 'blur' },
                    { validator: checkDiscount, trigger: 'blur' }
                ]
            },
            beforeAfterTimeOptions: [
                {
                    value: 1,
                    label: "1小时",
                },
                {
                    value: 2,
                    label: "2小时",
                },
                {
                    value: 3,
                    label: "3小时",
                },
                {
                    value: 4,
                    label: "4小时",
                },
                {
                    value: 5,
                    label: "5小时",
                },
                {
                    value: 24,
                    label: "全天",
                },
            ],
            reserveDaysOptions: [
                {
                    value: 1,
                    label: "1天",
                },
                {
                    value: 2,
                    label: "2天",
                },
                {
                    value: 3,
                    label: "3天",
                },
                {
                    value: 4,
                    label: "4天",
                },
                {
                    value: 5,
                    label: "5天",
                },
                {
                    value: 6,
                    label: "6天",
                },
                {
                    value: 7,
                    label: "7天",
                },
                {
                    value: 8,
                    label: "8天",
                },
                {
                    value: 9,
                    label: "9天",
                },
                {
                    value: 10,
                    label: "10天",
                },
            ],
            airDiscount: [
                {
                    value: 0,
                    label: "不限",
                },
                {
                    value: -1,
                    label: "预定0折及以下",
                },
                {
                    value: 3,
                    label: "预定3折及以下",
                },
                {
                    value: 4,
                    label: "预定4折及以下",
                },
                {
                    value: 5,
                    label: "预定5折及以下",
                },
                {
                    value: 6,
                    label: "预定6折及以下",
                },
                {
                    value: 7,
                    label: "预定7折及以下",
                },
                {
                    value: 8,
                    label: "预定8折及以下",
                },
                {
                    value: 9,
                    label: "预定9折及以下",
                },
            ],
            canSubmit: true,

            businessOverweightBecauseList: [],
            delReasonList: [],
            newReasonList: [],
            individualStandard: 0,
            typeRadio: 0,
            tPdProductSet: null,
            errorType: false,
        }
    },
    components: {
        PositionLevelChoose,
        EmployeeLevelChoose,
    },
    created() {
    },
    mounted() {
    },
    async activated() {
        this.reactForm()
        this.type = this.$route.query.type;
        let res = await consumer_tPdProductSet({});
        if (res.code == '000000') {
            this.individualStandard = res.datas.individualStandard ? 1 : 0;
        } else {
            this.individualStandard = 0;
        }
        this.typeRadio = 0;
        if (this.type === 'edit') {
            this.id = this.$route.query.id;
            this.getDetail();
        }
        this.$refs.positionChoose.init({
            id: this.id,
            type: this.type,
            businessType: 1,
        });
        this.$refs.employeeChoose.init({
            id: this.id,
            type: this.type,
            businessType: 1,
        });

        this.getQueryBusinessOverweightBecause()
        this.getDiscountTypeList();
    },
    deactivated() {
        this.$refs.positionChoose.emptyPosition();
        this.$refs.employeeChoose.emptyPosition();
    },
    destroyed() {
    },
    watch: {
        positionVoArr(newVal) {
            console.log('newVal-positionVoArr', newVal)
        },
        employeeVoArr(newVal) {
            console.log('newVal-employeeVoArr', newVal)
        }
    },
    computed: {},
    filters: {},
    methods: {
        radioInput(e) {
            if (e == 0) {
                this.errorType = false;
            }
        },
        changeDiscount(type, val) {
            // type: 1:头等商务舱 2:超级经济舱 3:经济舱
            if (val !== 0) {
                return;
            }
            switch (type) {
                case 1:
                    this.formData.firstAndBusinessDiscountPrice = 2;
                    break
                case 2:
                    this.formData.superEconomyDiscountPrice = 2;
                    break
                case 3:
                    this.formData.economyDiscountPrice = 2;
                    break
            }
        },
        //获取经济舱位枚举值
        async getDiscountTypeList() {
            let res = await consumer_web_policyConfig_queryDiscountType();
            this.airDiscount = res.datas;
        },
        changeFlightPolicy (val) {
            if (['1', '2'].includes(val.toString()) && this.formData.theDiscount) {
                this.formData.theDiscount = 0;
            }
        },
        reactForm() {
            this.id = ''
            this.formData = {
                businessType: 1,
                beforeAfterTime: '',
                flightControlRules: 1, // 机票提前预定管控规则:1.允许预定,只做提示;2.选择原因,3.不允许预定
                flightDiscountControlRules: 1, // 机票舱等折扣管控规则:1.允许预定,只做提示;2.选择原因,3.不允许预定
                flightLowPriceControlRules: 1, // 机票最低价管控规则:1.允许预定,只做提示;2.选择原因,3.不允许预定
                flightPolicy: 1, // 飞机政策限制:1.不限,2.头等/公务舱及以下,3.经济舱,4.不允许预定
                positionIds: [], // 职位id
                userIds: [], // 用户id
                reserveDays: '', // 提前预定:天数
                reserveDaysRadio: 0,
                beforeAfterTimeRadio: 0,
                economyDiscount: 0, // 经济舱位折扣
                economyOtherDiscount: '', // 经济舱其他折扣value
                firstAndBusinessDiscount: 0, // 头等/商务舱位折扣
                firstAndBusinessOtherDiscount: '', // 头等/商务其他舱位折扣value
                superEconomyDiscount: 0, // 超级经济舱位折扣
                superEconomyOtherDiscount: '', // 超级经济舱其他折扣value
                flightChangeControlRules: 0,
                flightAdditionalService: 0, // 是否允许机票增值服务 0不允许 1允许
                flightAdditionalServiceMoney: '', // 增值服务上线金额
            }
            this.newReasonList = []
            this.delReasonList = []
        },
        getDetail() {
            this.loading = true
            consumer_web_policyConfig_getFlightPolicyDetail({id: this.id}).then(res => {
                this.loading = false
                let formData = Object.assign({}, this.formData, res.datas)
                if (!formData.reserveDays) {
                    formData.reserveDays = ''
                }
                if (formData.reserveDays > 0) {
                    formData.reserveDaysRadio = 1
                }
                if (formData.beforeAfterTime > 0) {
                    formData.beforeAfterTimeRadio = 1
                } else {
                    formData.beforeAfterTime = ''
                }
                if (!formData.flightControlRules) {
                    formData.flightControlRules = 1
                }
                if (!formData.flightDiscountControlRules) {
                    formData.flightDiscountControlRules = 1
                }
                if (!formData.flightLowPriceControlRules) {
                    formData.flightLowPriceControlRules = 1
                }
                if (!formData.flightChangeControlRules) {
                    formData.flightChangeControlRules = 0;
                }
                if (!formData.flightAdditionalService) {
                    formData.flightAdditionalService = 0;
                }
                if (formData.flightAdditionalServiceMoney === null) {
                    formData.flightAdditionalServiceMoney = '';
                }
                if (formData.economyOtherDiscount === null) {
                    formData.economyOtherDiscount = '';
                }
                if (formData.firstAndBusinessOtherDiscount === null) {
                    formData.firstAndBusinessOtherDiscount = '';
                }
                if (formData.superEconomyOtherDiscount === null) {
                    formData.superEconomyOtherDiscount = '';
                }
                if (!formData.positionNames || !formData.positionNames[0]) {
                    this.typeRadio = 1;
                    let userBox = formData.userNames;
                    formData.userNames = [];
                    userBox.forEach((item,index) => {
                        formData.userIds.push(item.userId);
                        formData.userNames.push(item.userName);
                    });
                    this.$refs.employeeChoose.setUserInfo({
                        userIds: formData.userIds,
                        userNames: formData.userNames,
                    });
                }
                if (!formData.userNames || !formData.userNames[0]) {
                    this.typeRadio = 0;
                }
                this.errorType = false;
                if (this.typeRadio == 1 && this.individualStandard == 0) {
                    // 数据为员工差标但是没有开启员工差标
                    this.errorType = true;
                }

                this.formData = formData;
            }).catch(() => {
                this.loading = false
            })
        },
        changeReserveDays(val) {
            if (val === 0) {
                this.formData.reserveDays = ''
            } else {
                this.formData.reserveDays = 1
            }
        },
        changeBeforeAfterTime(val) {
            if (val === 0) {
                this.formData.beforeAfterTime = ''
            } else {
                this.formData.beforeAfterTime = 1
            }
        },
        cancel() {
            this.reactForm()
            // this.$router.go(-1)
            this.$router.push({
                name: 'admin-evection-flightpolicy',
            })
        },
        checkForm() {
            return new Promise(resolve => {
                this.$refs.form.validate(valid=>{
                    resolve(valid)
                })
            })
        },
        async submit() {
            if (!this.canSubmit) {
                return
            }
            // 校验
            let hasError = false
            this.newReasonList.forEach(value => {
                if (value.msg === '') {
                    hasError = true
                }
            })
            if (hasError) {
                return
            }
            if(this.formData.flightAdditionalServiceMoney !== '' && this.formData.flightAdditionalServiceMoney < 0) {
                this.$message.warning('增值服务上限金额不能填入负数')
                return
            }
            if (this.formData.flightAdditionalServiceMoney.toString().includes('.')){
                this.$message.warning('增值服务上限金额不能填入小数')
                return
            }
            let checkResult = this.checkForm();
            if (!checkResult) {
                return
            }
            this.canSubmit = false
            this.loading = true
            /*if (this.formData.reserveDays < 0) {
                this.canSubmit = true
                this.loading = false
                this.$message({
                    type: "warning",
                    message: '提前预定天数不能为负数'
                })
                return;
            }*/
            let positionVoId = [];
            if (this.typeRadio == 0) {
                this.positionVoArr.forEach(value => {
                    if (value.isEcho) {
                        positionVoId.push(value.id)
                    }
                });
                if (positionVoId.length === 0) {
                    this.canSubmit = true;
                    this.loading = false;
                    this.$message({
                        type: "warning",
                        message: '请先选择适用职级'
                    });
                    return;
                }
            }
            this.formData.positionIds = positionVoId;

            let employeeVoArr = [];
            if (this.typeRadio == 1) {
                this.$refs.employeeChoose.staffIdList.forEach(value => {
                    employeeVoArr.push(value)
                });
                if (employeeVoArr.length === 0) {
                    this.canSubmit = true;
                    this.loading = false;
                    this.$message({
                        type: "warning",
                        message: '请先选择适用员工'
                    });
                    return;
                }
            }
            this.formData.userIds = employeeVoArr;

            let formData = JSON.parse(JSON.stringify(this.formData))
            if (formData.flightPolicy === 1 || formData.flightPolicy === 4) {
                formData.beforeAfterTime = null;
                formData.flightControlRules = null;
                formData.flightDiscountControlRules = null;
                formData.flightLowPriceControlRules = null;
                formData.reserveDays = null;
                formData.reserveDaysRadio = null;
                formData.beforeAfterTimeRadio = null
            }
            if (formData.flightAdditionalServiceMoney === '') {
                formData.flightAdditionalServiceMoney = null;
            }
            formData.exceedingReasons = JSON.parse(JSON.stringify(this.newReasonList))
            const params = {
                id: this.id,
                code: this.delReasonList
            }
            if (this.delReasonList.length > 0) {
                consumer_web_policyConfig_deletePolicyByBusinessType(params)
            }
            if (this.type === 'add') {
                consumer_web_policyConfig_addTravelPolicy(formData).then(res => {
                    this.$message({
                        type: "success",
                        message: '新增成功'
                    })
                    this.cancel()
                }).catch(() => {

                }).finally(() => {
                    this.loading = false
                    this.canSubmit = true
                })
            } else {
                consumer_web_policyConfig_updatePolicyByBusinessType(formData).then(res => {
                    this.$message({
                        type: "success",
                        message: '修改成功'
                    })
                    this.cancel()
                }).catch(() => {

                }).finally(() => {
                    this.loading = false
                    this.canSubmit = true
                })
            }


        },

        channelInputLimit(e) {
            let key = e.key;
            if (key === "e" || key === ".") {
                e.returnValue = false;
                return false;
            }
            return true;
        },
        changeDay(value) {
            if (parseInt(value) < 0) {
                return 0
            } else if (value.length>=2) {
                return parseInt(value.slice(0,2))
            } else {
                return parseInt(value)
            }
        },

        getQueryBusinessOverweightBecause() {
            const params = {businessType: 1, configId: this.id}
            consumer_trains_common_queryBusinessOverweightBecause(params).then(res => {
                this.businessOverweightBecauseList = res.datas
            })
        },
        delThisReason(val, index) {
            this.delReasonList.push(val.code)
            this.businessOverweightBecauseList.splice(index, 1)
            // const params = {
            //     id: this.id,
            //     code: [val.code]
            // }
            // consumer_web_policyConfig_deletePolicyByBusinessType(params).then(res => {
            //     this.businessOverweightBecauseList.splice(index, 1)
            // })
        },
        addNewReason() {
            this.newReasonList.push({
                msg: '',
                isNew: true
            })
        },
        delNewReason(index) {
            this.newReasonList.splice(index, 1)
        },
        showReasonError(val) {
            if (val.msg === '' && !val.isNew) {
                return true
            } else {
                return false
            }
        },
        focusNewReason(index) {
            this.newReasonList[index].isNew = false
        }
    }
}
